import React from "react";

function Footer() {
    return(
        <>
            <footer className="app-footer mt-4">
                <p className="copy">&copy; Copyright {new Date().getFullYear()}</p>
            </footer>  
        </>
    );
}

export default Footer;