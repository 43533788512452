import { Button, Container, Form, FormControl, FormText, InputGroup } from "react-bootstrap";
import { useContext, useState } from "react";
import { api_url, api_token } from "../../context";
import axios from "axios";
import PageHeader from "../molecules/PageHeader";

const CSV_HEADER_KEYS = [
    'first_name',
    'last_name',
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'home_phone',
    'mobile_phone',
    'work_phone',
    'primary_email',
    'secondary_email',
    'organization',
    'dnc_request_date'
]
export default function Import() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [file, setFile] = useState(null);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setError(false);
        setSuccess(false);
        const reader = new FileReader();
        reader.onload = function(e) {
            const str = e.target.result;
            const delim = ',';
            //reference https://dev.to/theallegrarr/read-csv-files-in-react-apps-without-installing-any-package-hn7
            const headers = str.slice(0,str.indexOf('\n')).split(delim);
            const rows = str.slice(str.indexOf('\n')+1).split('\n');
    
            const newArray = rows.map( row => {
                const values = row.split(delim);
                const eachObject = headers.reduce((obj, header, i) => {
                    obj[header] = values[i];
                    return obj;
                }, {})
                return eachObject;
            })

            const transformedArray = newArray.map(v => {
                const values = Object.values(v);
                const prepValues = {};
                values.forEach((vv, index) => {
                    prepValues[CSV_HEADER_KEYS[index]] = vv;
                })
                return prepValues;
            })

            Promise.all(transformedArray.map( v => sendRequest(v)))
            .then(() => {
                setSuccess(true);
                setError(false);
                setLoading(false);
                document.getElementById('form').reset();
            })
        }
        reader.readAsText(file);
    }

    const sendRequest = (data) => {
      
        const headers = {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+api_token,
          }

        return axios.post(
            api_url+'/add_dnc',
            data,
            {
                headers: headers
            }
        );
    }
    return (
        <Container>
            <PageHeader title="Import" />
            
            {!loading ?
            <Form
                id="form"
                onSubmit={handleSubmit}>
                <InputGroup>
                    <Form.Control
                        type="file"
                        id="file"
                        bsCustomPrefix="custom-file-input"
                        className="h-100"
                        accept=".csv"
                        onChange={(e) => {
                            setFile(e.target.files[0])
                        }}
                      
                    >
                        

                    </Form.Control>
                    <Button variant="primary" type="Submit">Submit</Button>
                </InputGroup>
              
                <FormText style={{textAlign: 'left'}}>
                    Please upload a csv file.
                    <p>Note: must be in  these format: <br />
                    <code>
                        First Name, Last Name, Address1, Address 2, City, State, Zip, Home Phone, Mobile Phone, Work Phone, Email Address, Secondary Email Address, Organization
                    </code>
                    </p>
                 </FormText>
                
            </Form>  : null }

            {loading ? <p className="text-info">Importing please wait ....</p> : null}
            {error ? <p className="text-danger">Something went wrong, please try again ...</p> : null}
            {success ? <p className="text-success">Import successful... <a href="/"> return to contacts</a></p> : null}
        </Container>
    )
}
